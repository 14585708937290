import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routesCommon: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./pages/index/index.module').then(m => m.IndexPageModule) 
  },
 
  {
    path: 'cbapp',
    children: [
      { path: 'template-page', loadChildren: () => import('./pages/template-page/template-page.module').then(m => m.TemplatePagePageModule)  }
    ]
  },


  {
    path: 'bergensportsreiser',
    children: [
      { path: 'order', loadChildren: () => import('./pages/bergensportsreiser/order/order.module').then(m => m.OrderPageModule)  }
    ]
  },

];

const routes: Routes = [

  ...routesCommon,
    
  { // Brukes på test
    path: 'api/:cid',
    children: routesCommon
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
