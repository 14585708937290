import { Injector } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';

import { DATAID } from './data.service';
import { BtEvent } from './event.service';
import { MiscUtil } from '../util/misc.util';

import { APP_VERSION_CODE, APP_VERSION_NAME } from '../util/version';



export class ApiService {

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log(MiscUtil.getLogText("ApiService.constructor"));


    if(this.bas.envtest) console.log("ApiService, hostname: " + (this.bas.nossr ? window.location.hostname : "SSR"));

    // this.refreshHostConfig();


    // this.bas.ds.get(DATAID.APP_CONFIG).subscribe(ac => {
    //   if(this.bas.envtest) console.log(MiscUtil.getLogText("ApiService.APP_CONFIG.subscribe, ac: "), ac);

    //   if (ac[DATAID.DS_SAVE_TIME]) {

    //   }
    // });

    this.bas.es.on(BtEvent.DS_INIT, () => {
      let urlVars = MiscUtil.getUrlVars(bas.LOC.path());
      this.bas.ds.set(DATAID.APP_URL_QUERY_PARAMS, urlVars);

      
      if(this.bas.envtest) console.log(MiscUtil.getLogText("ApiService.DS_INIT"));

      if(this.bas.envtest) if(this.bas.envtest) console.log(MiscUtil.getLogText("ApiService.DS_INIT") + ", current config: ", this.bas.ds.config);

      if (this.bas.ds.config?.appConfig) {

        let isFrame = this.bas.nossr  && window.location !== window.parent.location;
        if(this.bas.envtest) console.log("isFrame: " + isFrame + ", standalone: " + this.bas.standalone + ", urlVars: ", urlVars  ,", login: ", this.bas.ds.login);
        
        
        if (isFrame) {
          document.getElementsByTagName("html")[0].classList.add("cbapi-iframe");
        }

        if (!this.bas.ds.login.success && urlVars.bttoken) { // isFrame && 
          // this.bas.ds.login = { success: true, accessToken: urlVars.bttoken };
          if(this.bas.envtest) console.log("checking logged in user: " + this.bas.ds.config?.userId);
          this.checkLoggedInUser({}, { token:  urlVars.bttoken }).then((res) => {
            if (res.success) { 
              if(this.bas.envtest) console.log("checkLoggedInUser, user logged in,  reloading page ??");
              this.bas.nossr && window.location.reload();
            } else {
              
              this.bas.es.trigger(BtEvent.APP_READY);
        
            }
          });
        } else {


          
        this.bas.es.trigger(BtEvent.APP_READY);
        

        if (this.bas.standalone) return; 
        
        setTimeout(() => {
          this.refreshAppConfig();
        }, 300)

        }


      } else {

        this.refreshAppConfig();

      }





      
    })
    
    
   
  }


  refreshAppConfig() {


    var cid = this.bas.ds.findCid();
      
    if(this.bas.envtest) console.log(MiscUtil.getLogText("ApiService.refreshAppConfig, cid: " + cid));

    let currentConfig = this.bas.ds.config?.appConfig;
    let lastUpdated = currentConfig ? currentConfig.updatedLong : 0;

    let params = { 
      aType: "app",
      action: "ping",
      hostname: window.location.hostname,
      lastUpdate: lastUpdated,
      libVersion: 5,
      appType: this.bas.settings.appType || "btng",
      versionCode: APP_VERSION_CODE,
      nativeAppVersionCode: APP_VERSION_NAME,
      cid: cid
    };

    if(this.bas.envtest) console.log("ApiService.refreshAppConfig, params: ", params);

    this.bas.ws.json(params, { }).then((res) => {

      if(this.bas.envtest) console.log("ApiService.refreshAppConfig.then: ", res);

      if (res.success && res.appConfig) {
        if (!res.cid) {
          res.cid = "root";
          res.company = {
            username: "root",
            root: true
          }
        }
        this.bas.ds.save(DATAID.APP_CONFIG, res).then(() => {

          if(this.bas.envtest) console.log("config updated, reloading page, ac: ", res);
          
          if (this.bas.standalone) {
            this.bas.es.trigger(BtEvent.APP_READY);
          } else {
            window.location.reload();
            return;
          }
          
        

          if (this.bas.es.isReady) {
            //TODO: gi beskjed om at siden må oppdateres? 
          } else {
            this.bas.es.trigger(BtEvent.APP_READY);
          }
          
        });
      } else {



        if (this.bas.es.isReady) {
          //TODO: gi beskjed om at siden må oppdateres? 
        } else {
          this.bas.es.trigger(BtEvent.APP_READY);
        }
        
      }


      // this.updateInitData(res);

    }).catch((err) => {
      if(this.bas.envtest) console.log("ApiService.refreshAppConfig.failed, err ", err);
      
    });

  }









  login(params:any = {}, opts:any = {}):Promise<any> {

    MiscUtil.extend(params, {
      aType: "auth",
      action: "login",
    });

    return this.bas.ws.json(params, { }).then((res) => {

      if(this.bas.envtest) console.log("ApiService.login: ", res);

      return this.bas.ds.save(DATAID.APP_LOGIN, res).then((saveRes) => {
        if (res.success) {
          if(this.bas.envtest) console.log("login.success, reloading page, saveRes: ", saveRes);
          // window.location.reload();
        }
        return res;
      });

      // return res;

    }).catch((err) => {
      if(this.bas.envtest) console.log("ApiService.login.failed, err ", err);
    });

  }

  checkLoggedInUser(params:any = {}, opts:any = {}):Promise<any> {

    return new Promise<any>((success, rej) => {

      MiscUtil.extend(params, {
        aType: "auth",
        action: "checkLoggedInUser",
      });
  
      let headers = opts.token ?  {
        "cb-auth-token": opts.token,
      } : { };
  
      this.bas.ws.json(params, { 
        checkSuccess: false,
        headers: headers
      }).then( (res) => {
  
        if(this.bas.envtest) console.log("ApiService.checkLoggedInUser: ", res);
  
        if (res.success) {
          this.bas.ds.save(DATAID.APP_LOGIN, res).then(() => {
            success(res);
          });
  
        } else {
          success(res);
        }
        
        // return res;
  
      }).catch((err) => {
        if(this.bas.envtest) console.log("ApiService.checkLoggedInUser.failed, err ", err);
        rej({ success: false });
      });

    });


  }

  logout():Promise<any> {
    let refreshToken = this.bas.ds.login.refreshToken;
    this.bas.ds.save(DATAID.APP_LOGIN, { success: false});

    return this.bas.ws.json(
      { aType: "auth", action: "logout" },
      { 
        headers: { "cb-auth-token": refreshToken },
        excludeLoginHeaders: true,
        checkAuthStatus: false
      }
    ).then((res) => {

      if(this.bas.envtest) console.log("ApiService.logout: ", res);

      
      return res;

    }).catch((err) => {
      if(this.bas.envtest) console.log("ApiService.logout, err ", err);
    });

  }

  lostPassword(params:any = {}, opts:any = {}):Promise<any> {

    MiscUtil.extend(params, {
      aType: "auth",
      action: "lostPassword",
      platform: "cbapi5"
    });

    return this.bas.ws.json(params, { }).then((res) => {

      if(this.bas.envtest) console.log("ApiService.lostPassword: ", res);

      // this.bas.ds.save(DATAID.APP_LOGIN, res);

      return res;

    }).catch((err) => {
      if(this.bas.envtest) console.log("ApiService.lostPassword.failed, err ", err);
    });

  }


}
