//

export class BtContent {
  label: string;
  value: any;
  type: "string" | "double" | "email" | "telephone";
  css: string;
  show: (() => boolean);
 
  constructor(options: {
      label?: string;
      value?: any;
      type?: "string" | "double";
      css?: string;
      show?: () => boolean | boolean;
    } = {}) {

    this.label = options.label || '';
    this.value = options.value;
    this.type = options.type || 'string';
    this.css = options.css || '';

    this.show = () => { return true };
    if ( options.show) {
      if (typeof options.show === "boolean") this.show = () => { return true };
      else this.show = options.show;
    }

  }


}
