import { Injector } from '@angular/core';
import { MiscUtil } from '../util/misc.util';
import { BooktechAppService } from './booktech-app.service';


export class AppCommonService {


  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if (this.bas.envtest) console.log("AppCommonService.constructor");

   
  }


  productGet(id:number|string, extraParams:any = { }):Promise<any> {
    let params = {
      aType: "order", 
      action: "getProduct", 
      id: id
    }
    MiscUtil.extend(params, extraParams || { });

    return this.bas.ws.json(params).then((json) => {
      // if (this.bas.envtest) console.log("getProduct, json: ", json);
      if (json.success) {
        let p = json.product;
        this.updateProductObj(p);
        // return p;
      }
      return json;

    });
  }

  // products start
  productsGrid = { 
    xs: 24, 
    sm: 12, 
    md: 8, 
    lg: 8, 
    xl: 6,  
    xxl: 4,  
    gutter: [8, 16] 
  };
  
  updateProductObj(p:any, lang?:string, qps?:any) {
    qps = qps || { };
    if (!lang) lang = this.bas.ds.findLang();
    p.urlFull = this.bas.ws.getBaseUrl(p.cid) + p.url + "?source=" +(qps.source || "cbapi5");


    let pInfo = "" + p.findSubType; // SubType og area 
    if (p.findArea) pInfo += (pInfo ? " " : "") + p.findArea;
    if (!pInfo) pInfo = p.typeObj.mkName; // TODO

    p.cbsiteRoute = [ 
      "/" + lang, 
      p.cid,
      "p",
      p.typeObj.mkName,
      pInfo,
      p.name + "-" + p.id
    ]

    if (p.images) {
      let imageList = [];
      for (let iid of p.images) {
        imageList.push(p.imageMap[iid]);
      }
      p.imageList = imageList;
    }


  }

  productsGet(ctx:any, extraParams:any = { }):Promise<any> {
    let params = {
      aType: "order", 
      action: "getProducts", 
    }

    if (this.bas.envtest) console.log("productsGet.ctx: ", ctx);

    let qps = ctx.queryParams || { };

    MiscUtil.extend(params, qps);
    MiscUtil.extend(params, extraParams || { });

    let lang = this.bas.ds.findLang();
    // let cid = this.bas.ds.findCid();


    return this.bas.ws.json(params).then((json) => {
      // if (this.bas.envtest) console.log("getProducts, json: ", json);
      if (json.success) {
        ctx.productTypes = json.productTypes || { };
        if (ctx.type === undefined) ctx.type = json.type || (ctx.productTypes ? ctx.productTypes[0].id : undefined);
        for (let p of  json.products || []) {
         this.updateProductObj(p, lang, qps);
        }
        ctx.products = json.products || [];

        

        for (let ptid of Object.keys(json.productsMap || { })) {
          for (let p of  json.productsMap[ptid] || []) {
            this.updateProductObj(p, lang, qps);
            // p.urlFull = this.bas.ws.getBaseUrl(p.cid) + p.url + "?source=" +(qps.source || "cbapi5");

          }
        }


        ctx.productsMap = json.productsMap || { };

      
        
      }

      return json;
    });
  }

  productsOnClick(event:any, ctx:any, p:any) {
    if (ctx.queryParams?.newWindow) {
      return;
    }


    event.preventDefault();
    if (this.bas.envtest) console.log("productClick: ", p);

    this.bas.es.sendParentMessageRedir(p.urlFull);
  }

  // products end



}
