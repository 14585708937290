import { Injector } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';
import { MiscUtil } from '../util/misc.util';

export class OrderService {

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("OrderService.constructor");

   
  }

  
  placeOrder(cart:any, extraParams:any, jsonData?:any):Promise<any> {
    
    var user = this.bas.ds.login.user;
    var params:any = { 
      actionType: "appService", 
      action: "placeOrder", 
      acceptEula: true
    };

    if (user) params.userId = user.id;

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, MiscUtil.getCartParams(cart)); 

    if (this.bas.envtest) console.log("OrderService.placeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.placeOrder.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }

}
