import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MbscModule } from '@mobiscroll/angular-ivy';

import { NgZorroAntdModule } from './ng-zorro-antd.module';

import { NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { nb_NO, en_US } from 'ng-zorro-antd/i18n';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import nb from '@angular/common/locales/nb';

import { AppService } from './services/app.service';
// import { FilterPipeModule } from 'ngx-filter-pipe';


// import { BtPipesModule } from './lib/core/pipes/bt-pipes.module';
// import { IFrameResizerDirective } from './lib/core/directive/iframe-resizer.directive';


registerLocaleData(en);
registerLocaleData(nb);

@NgModule({
  declarations: [
    AppComponent,
    // IFrameResizerDirective,
  ],
  imports: [ 
    MbscModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    NgZorroAntdModule,

    // FilterPipeModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: nb_NO },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      },
    },
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
